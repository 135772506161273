<template>
  <div class="records">
    <div class="task-side">
      <div class="task-side-title">品种研发</div>

      <div class="land-tab">
        <div
          :class="['land-tab-items', index === tabIndex ? 'active' : '']"
          v-for="(item, index) in tabList"
          :key="index"
          @click="changTab(item, index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="main">
      <PageTop
        :seedShow="true"
        :addShow="true"
        @addMeans="addRecords"
        @getSeedSearch="getSeedSearch"
      >
        <p slot="addName">添加记录</p>
      </PageTop>
      <div class="task-main">
        <MyTable
          class="animated fadeInUp"
          :tableData="recordList"
          :configTable="configTable"
          @handleDelete="handleDelete"
          @handleEdit="handleEdit"
        ></MyTable>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="671px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ popTitle }}
        </div>
        <div class="add-form">
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            hide-required-asterisk
          >
            <el-form-item
              label="时间"
              :label-width="formLabelWidth"
              prop="date"
            >
              <el-date-picker
                v-model="form.date"
                type="date"
                placeholder="请选择时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="品名(通用名)"
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input
                v-model.trim="form.name"
                autocomplete="off"
                placeholder="请输入品名（通用名）"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="购买地点"
              :label-width="formLabelWidth"
              prop="address"
            >
              <el-input
                v-model.trim="form.address"
                autocomplete="off"
                placeholder="请输入购买地点"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="生产企业"
              :label-width="formLabelWidth"
              prop="company"
            >
              <el-input
                v-model.trim="form.company"
                autocomplete="off"
                placeholder="请输入生产企业"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="登记证号"
              :label-width="formLabelWidth"
              prop="card_no"
            >
              <el-input
                v-model.trim="form.card_no"
                autocomplete="off"
                placeholder="请输入登记证号"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="购入量"
              :label-width="formLabelWidth"
              prop="number"
            >
              <el-input
                v-model.number.trim="form.number"
                autocomplete="off"
                placeholder="请输入购入量"
                maxlength="20"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="上传图片"
              :label-width="formLabelWidth"
              prop="cover"
            >
              <el-upload
                :class="['uploadCover', form.file_path ? 'active' : '']"
                action="https://resource.darsing.net/api/v1/img"
                name="img"
                :limit="1"
                list-type="picture-card"
                :show-file-list="true"
                :file-list="fileList"
                :headers="uploadHeaders"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarFail"
                :on-remove="removeFile"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>

            <el-form-item
              label="所属类型"
              :label-width="formLabelWidth"
              prop="title"
            >
              <el-select v-model="form.title" placeholder="请选择所属类型">
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, index) in tabList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="备注"
              :label-width="formLabelWidth"
              prop="desc"
            >
              <el-input
                v-model.trim="form.desc"
                type="textarea"
                autocomplete="off"
                placeholder="请输入备注"
                maxlength="200"
              >
              </el-input>
            </el-form-item>
            <el-form-item label=" " class="dialog-footer">
              <el-button class="ok-button" type="primary" @click="okAdd('form')"
                >确 定</el-button
              >
              <el-button class="no-button" @click="noAdd('form')"
                >取 消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { editTime3 } from "@/utils/editTime";

import {
  getHanshanRecordListApi,
  postHanshanRecordEditApi,
  postHanshanRecordAddApi,
  postHanshanRecordDelApi,
} from "@/request/api.js";
export default {
  components: {
    PageTop: () => import("@/components/PageTop.vue"),
    MyTable: () => import("@/components/MyTable.vue"),
  },
  name: "viewRecords",
  data() {
    return {
      addShow: false,
      popTitle: "",
      form: {
        title: "",
        name: "",
        date: "",
        address: "",
        company: "",
        card_no: "",
        number: "",
        desc: "",
        file_path: "",
      },
      rules: {
        name: [{ required: true, message: "请输入品名", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        company: [{ required: true, message: "请输入企业", trigger: "blur" }],
        card_no: [{ required: true, message: "请输入证号", trigger: "blur" }],
        number: [{ required: true, message: "请输入购入量", trigger: "blur" }],
        title: [{ required: true, message: "请选择类型", trigger: "change" }],
        date: [{ required: true, message: "请选择日期", trigger: "change" }],
      },
      formLabelWidth: "120px",
      fileList: [],
      uploadHeaders: {
        Authorization: localStorage.getItem("darsingToken"),
      },
      tabList: [
        "农产品质量安全承诺书",
        "国家禁限用农药名录",
        "经营主体基本情况",
        "投入品管理记录",
        "投入品购入记录",
        "投入品管理记录",
        "农产品施药记录",
        "农产品施肥记录",
        "农产品监管记录",
        "国家禁限用农药名录",
        "农产品检测记录",
        "收获及销售记录",
        "农事记录",
      ],
      title: "农产品质量安全承诺书",
      tabIndex: 0,
      configTable: [
        {
          prop: "date",
          label: "时间",
          width: "118",
        },
        {
          prop: "name",
          label: "品名（通用名）",
          width: "160",
        },
        {
          prop: "address",
          label: "购买地点",
          width: "200",
        },
        {
          prop: "company",
          label: "生产企业",
          width: "160",
        },
        {
          prop: "card_no",
          label: "登记证号",
          width: "160",
        },
        {
          prop: "number",
          label: "购入量",
          width: "160",
        },
        {
          prop: "user_name",
          label: "经办人",
          width: "160",
        },
        {
          prop: "desc",
          label: "备注",
          width: "160",
        },

        {
          prop: "",
          label: "操作",
          width: "",
          delType: true,
          editType: true,
        },
      ],
      pn: 1,
      ps: 10,
      total: 0,
      recordList: [],
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getList();
  },
  watch: {
    addShow(value) {
      if (!value) {
        this.form = {};
        this.fileList = [];
        this.getList();
      }
    },
  },
  methods: {
    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
    },

    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        if (this.popTitle === "添加记录") {
          this.addRecord();
        } else {
          this.editRecord();
        }
      });
    },
    async editRecord() {
      this.form.date = editTime3(this.form.date);
      const { code } = await postHanshanRecordEditApi({
        manor_id: this.farmID,
        record_id: this.form.id,
        ...this.form,
      });
      if (code !== 0) return;
      this.addShow = false;
      this.$notify({
        title: "成功",
        message: "已修改",
        type: "success",
      });
    },
    async addRecord() {
      this.form.date = editTime3(this.form.date);
      const { code } = await postHanshanRecordAddApi({
        manor_id: this.farmID,
        ...this.form,
      });
      if (code !== 0) return;
      this.addShow = false;
      this.$notify({
        title: "成功",
        message: "已添加",
        type: "success",
      });
    },
    setAddClose() {
      this.addShow = false;
    },
    async handleEdit(row) {
      if (row.file_path) {
        this.fileList = [
          {
            url: this.uploadImgs + row.file_path,
          },
        ];
      }
      this.form = row;
      this.popTitle = "编辑记录";
      this.addShow = true;
    },
    addRecords() {
      this.popTitle = "添加记录";
      this.addShow = true;
    },
    removeFile() {
      this.form.file_path = "";
    },
    // 上传失败
    handleAvatarFail() {
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
    // 上传成功
    handleAvatarSuccess(res, file) {
      if (file.percentage === 100) {
        this.form.file_path = file.response.Data.imgid;
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      }
    },
    // 搜索
    getSeedSearch({ query_date, mid }) {
      this.query_date = query_date;
      this.mid = mid;
      this.pn = 1;
      this.getList();
    },
    async getList() {
      const { code, results } = await getHanshanRecordListApi({
        manor_id: this.farmID,
        mid: this.mid,
        query_date: this.query_date,
        title: this.title,
        pn: this.pn,
      });
      if (code != 0) return;
      results.data.map((item) => (item.date = item.date.slice(0, 10)));
      this.recordList = results.data;
      this.count = results.count;
      this.pn = results.pn;
      this.ps = results.ps;
    },
    changTab(title, index) {
      this.tabIndex = index;
      this.title = title;
      this.pn = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pn = val;
      this.getList();
    },
    // 提交关闭
    handleDelete({ id }) {
      this.$confirm("此操作将删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { code } = await postHanshanRecordDelApi({ record_id: id });
          if (code === 204) {
            this.getList();
            this.$notify({
              title: "提示",
              message: "已删除",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.records {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 0 48px 100px;
  display: flex;
  justify-content: space-between;
  .task-side {
    width: 312px;
    height: 98%;
    background: #142d4c;
    padding: 34px 21px;
    margin-top: 16px;
    .task-side-title {
      width: 100%;
      line-height: 22px;
      height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      padding-left: 11px;
      border-left: 4px solid #3e90e5;
      border-radius: 2px;
      margin-bottom: 27px;
    }

    .land-tab {
      width: 100%;
      height: 60%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
      .land-tab-items {
        width: 100%;
        height: 32px;
        text-align: center;
        line-height: 32px;
        background: #173355;
        border: 1px solid #1b3e68;
        border-radius: 2px;
        font-size: 14px;
        color: #fff;
        margin-bottom: 18px;
        cursor: pointer;
        &.active {
          background-color: #3e90e5;
        }
      }
    }
  }
  .main {
    width: calc(100% - 328px);
    // height: 750px;
    /deep/.land-admin {
      height: auto;
    }
  }
  .big-pop {
    /deep/.el-dialog {
      width: 671px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }
  .big-pop {
    /deep/.el-form-item {
      margin-bottom: 32px;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      margin-top: 40px;
      padding: 0 0 0 119px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 671px;
      height: 859px;
      background: url("../../assets/image/records/bg.png") no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px;
      overflow: hidden;
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;
        align-items: center;
        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .block {
        width: 100%;
        height: calc(100% - 124px);
        overflow: auto;
        padding: 0 2px;

        &::-webkit-scrollbar {
          width: 1px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #ccc;
        }
      }
      /deep/.uploadCover {
        width: 158px;
        height: 105px;
        display: flex;
        .el-upload--picture-card {
          width: 100%;
          height: 100%;
          line-height: 105px;
        }
        .el-upload-list--picture-card .el-upload-list__item {
          width: 158px;
          height: 105px;
        }
        &.active {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
      /deep/.el-card {
        background-color: #081c30;
        border: none;
      }

      /deep/.el-timeline-item__tail {
        border-left: 2px solid #385576;
      }

      /deep/.el-timeline-item__timestamp {
        font-size: 18px;
        color: #fff;
      }

      /deep/.el-card__body {
        width: 450px;
        min-height: 83px;
        padding: 18px 15px;
      }

      /deep/.el-timeline-item__node {
        background-color: #67c23a;
      }

      /deep/.el-timeline-item.active {
        .el-timeline-item__node {
          background-color: #e60000;
        }
      }

      .info-col1 {
        margin-bottom: 16px;

        span {
          font-size: 18px;
          color: #fff;
          margin-right: 16px;
        }

        .num {
          color: #e60000;
        }

        .num.active {
          color: #67c23a;
        }
      }

      .info-col2 {
        span {
          font-size: 14px;
        }

        .text1 {
          color: #57708d;
          margin-right: 14px;
        }

        .text2 {
          color: #fff;
          margin-right: 20px;
        }

        .text3 {
          color: #57708d;
          margin-right: 10px;
        }

        .text4 {
          color: #3e90e5;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
      .add-form {
        width: 100%;
        height: calc(100% - 56px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
    }
  }
}
</style>
